jQuery(document).ready(function ($) {
  var headerHight = 0;
  var spflag = true;
  var footercontactsShowHeight = $(window).width() < 768 ? 200 : 800;
  // var footercontactsShowHeight = 800;
  var animeSpd = 400;
  var scrollpos;

  // jQueryオブジェクトを変数に格納
  var $jsSitePagefooterNavisPrimaryTitle = $('.js-site-pagefooter-navis-primary-title');
  var $jsSitePageheaderBtn = $('.js-site-pageheader-btn');
  var $jsSitePageheaderMainnavi = $('.js-site-pageheader-mainnavi');
  var $jsSiteFootercontacts = $('.js-site-footercontacts');
  var $jsSitePageheaderMainnaviSearchbox = $('.js-site-searchheader');

  $('a[href^="#"]:not(.dns)').not('.js-tabnavi a').not('a[class*="js-"]').click(function () {
    var href = $(this).attr('href');
    var target = $(href === '#' || href === '' ? 'html' : href);
    if (target[0]) {
      var position = target.offset().top - headerHight;
      $('html, body').animate({
        scrollTop: position
      }, animeSpd, 'swing');
    }

    return false;
  });

  $("a.disabled").each(function () {
    $(this).removeAttr('href');
    $(this).find('i,svg').remove();
    $(this).find('span').text('応募受付は終了いたしました');
  });

  $("a.finished").each(function () {
    $(this).removeAttr('href');
    $(this).find('i,svg').remove();
    $(this).find('span').text('予約受付は終了いたしました');
  });

  var mediaQuery = matchMedia('(min-width: 768px)');

  // ページが読み込まれた時に実行
  handle(mediaQuery);

  // ウィンドウサイズが変更されても実行されるように
  mediaQuery.addListener(handle);

  function handle (mq) {
    if (mq.matches) {
      $('body').removeClass('is-sp');

      // フッターナビを開く
      $('.js-site-pagefooter-navis-primary-occont').removeClass('is-closed').attr('style', '');

      // ヘッダーナビ表示
      $jsSitePageheaderMainnavi.attr('style', '');

      // メニューアクティブ削除
      $('.js-sp-willopen').removeClass('is-open');

      removeBodyFix();
    } else {
      $('body').addClass('is-sp');
      $('.js-pageheader-mainnavi-category.js-sp-willopen').addClass('is-open');

      // フッターナビを閉じる
      $jsSitePagefooterNavisPrimaryTitle.removeClass('is-open');
      // メニューボタンをデフォルトに戻す
      $jsSitePageheaderBtn.removeClass('is-open');
      $('.js-site-pageheader-btn > div').removeClass('is-open');

      //　メニューアクティブ追加
      $('.js-sp-willopen + ul').attr('style', '');

      removeBodyFix();
    }

    spflag = $('body').hasClass('is-sp');
    setKvImage();
    windowHeightSet();
  }

  // スクロールヒントの設定
  new ScrollHint('.js-scrollable', {
    i18n: {
      scrollable: 'スクロールできます'
    }
  });

  // モーダルの設定
  var modaalConf = 'overlay_opacity: .9';
  if ($('.js-modaal-inline')[0]) {
    $('.js-modaal-inline').modaal({
      overlay_opacity: 0.9
    });
    $('.js-modaal-inline').each(function () {
      $($(this).attr('href')).hide();
    });
  }
  if ($('.js-modaal-video')[0]) {
    $('.js-modaal-video').modaal({
      type: 'video',
      overlay_opacity: 0.9,
      after_open: function () {
        console.log('after_open');
        $('.modaal-video-container').addClass('is-open');
      }
    });
  }
  if ($('.js-modaal-image')[0]) {
    $('.js-modaal-image').modaal({
      type: 'image',
      overlay_opacity: 0.9
    });
  }

  // カルーセルの設定
  if ($('.js-carousel-pc4')[0]) {
    $('.js-carousel-pc4').slick({
      dots: true,
      speed: animeSpd,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }
  if ($('.js-carousel-pc3')[0]) {
    $('.js-carousel-pc3').slick({
      dots: true,
      speed: animeSpd,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }
  if ($('.js-carousel-pc1')[0]) {
    $('.js-carousel-pc1').on('init', function () {
      $('.slick-dots li:first-child svg').addClass('is-active');
      $('.slick-current').addClass('is-active');
    }).on('beforeChange', function () {
      $('.slick-active svg').removeClass('is-active');
      $('.slick-current').removeClass('is-active');
    }).on('afterChange', function () {
      $('.slick-active svg').addClass('is-active');
      $('.slick-current').addClass('is-active');
    });
    $('.js-carousel-pc1').slick({
      dots: true,
      speed: 1000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      customPaging: function (slider, i) {
        return $('<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g><circle cx="7" cy="7" r="7" stroke="none"/><circle cx="7" cy="7" r="6"/></g></svg>');
      },
      prevArrow: '<div class="u-slide-arrow u-prev-arrow"><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 29"><defs><clipPath id="a"><path data-name="長方形 2478" transform="translate(-.142 -.142)" d="M0 0h16v29H0z"/></clipPath></defs><g data-name="マスクグループ 161" transform="rotate(180 7.929 14.429)" clip-path="url(#a)" opacity=".5"><path data-name="前面オブジェクトで型抜き 4" d="M14.885 26.787l-.994.994L0 13.89 13.89 0l.995.994L1.99 13.891l12.896 12.895z"/></g></svg></div>',
      nextArrow: '<div class="u-slide-arrow u-next-arrow"><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 29"><defs><clipPath id="a"><path data-name="長方形 2478" transform="translate(-.142 -.142)" d="M0 0h16v29H0z"/></clipPath></defs><g data-name="マスクグループ 161" transform="rotate(180 7.929 14.429)" clip-path="url(#a)" opacity=".5"><path data-name="前面オブジェクトで型抜き 4" d="M14.885 26.787l-.994.994L0 13.89 13.89 0l.995.994L1.99 13.891l12.896 12.895z"/></g></svg></div>'

    }).on('touchmove', function (event, slick, currentSlide, nextSlide) {
      $('.js-carousel-pc1').slick('slickPlay');
    });
  }

  function setKvImage () { // キービジュアルのサイズ変更 ＆ SP時にクリック範囲拡大
    if ($('.js-carousel-pc1').find('.js-u-carousel-kv-single')[0]) {
      var $slideSingle = $('.js-carousel-pc1').find('.js-u-carousel-kv-single');
      $slideSingle.each(function () {
        var imgAry = $(this).data('images');
        var cssText = '';
        if (spflag) {
          cssText = imgAry[0];
        } else {
          cssText = imgAry[1];
        }
        $(this).attr('style', 'background-image: url(' + cssText + ');');
				console.log(imgAry[0]);
      // SP時にクリック範囲拡大
        if ($(this).find("a").length > 0) {
          if (spflag) {
            $(this).on('click', function () {
              window.location = $(this).find('a').attr('href');
            });
          } else {
            $(this).off('click');
          }
        } else {
          $(this).off('click');
          // return false;
        }
      });
    }
  }
  setKvImage();

  // SP ヘッダー モデルボタン
  $('.js-site-pageheader-spnavi').on('click', function () {
    if (spflag) {
      if ($(this).hasClass('is-open')) {
        $(this).removeClass('is-open');
        $(' + div', this).stop(true, true).slideUp(animeSpd);
        removeBodyFix();
      } else {
        $(this).addClass('is-open');
        $(' + div', this).stop(true, true).slideDown(animeSpd);
        $('.site-pageheader-mainnavi').hide();
        if ($jsSitePageheaderBtn.hasClass('is-open')) {
          $jsSitePageheaderBtn.removeClass('is-open');
          $jsSitePageheaderBtn.addClass('is-close');
          $jsSitePageheaderBtn.children().removeClass('is-open');
          $jsSitePageheaderBtn.children().addClass('is-close');
        }
        setBodyFix();
      }
      return false;
    }
  });

  function setBodyFix () {
    scrollpos = $(window).scrollTop();
    $('body').addClass('is-fixed');
    $('html').addClass('is-fixed');
  }
  function removeBodyFix () {
    $('body').removeClass('is-fixed');
    $('html').removeClass('is-fixed');
  }

  // pc ヘッダー モデルナビ
  $('.js-site-pageheader-spnavi-ul').on('mouseenter', function () {
    if (!spflag) {
      $('a', this).addClass('is-open');
      $('a + div', this).stop(true, true).slideDown(animeSpd);
    }
  }).on('mouseleave', function () {
    if (!spflag) {
      $('a', this).removeClass('is-open');
      $('a + div', this).hide();
    }
  });

  // SP ヘッダー メニューボタン
  $jsSitePageheaderBtn.on('click', function () {
    if (spflag) {
      if ($(this).hasClass('is-open')) {
        $(this).removeClass('is-open');
        $(this).children().removeClass('is-open');
        $(this).children().addClass('is-close');
        $jsSitePageheaderMainnavi.slideUp(animeSpd);
        removeBodyFix();
      } else {
        $(this).addClass('is-open');
        $(this).children().addClass('is-open');
        $(this).children().removeClass('is-close');
        $('.js-site-pageheader-spnavi + div').hide();
        $('.js-site-pageheader-spnavi').removeClass('is-open');
        $jsSitePageheaderMainnavi.slideDown(animeSpd);
        setBodyFix();
      }
    }
  });

  // pc ヘッダー メニューナビ
  $('.js-pageheader-mainnavi-ul-li').each(function () {
    $(this).on('mouseenter', function () {
      if (!spflag) {
        $('> a', this).addClass('is-open');
        $('> a + ul', this).stop(true, true).slideDown(animeSpd);
      }
    }).on('mouseleave', function () {
      if (!spflag) {
        $('> a', this).removeClass('is-open');
        $('> a + ul', this).removeAttr("style");
      }
    });
  });

  // sp ヘッダーナビ開閉
  $('.js-pageheader-mainnavi-category').on('click', function () {
    if (spflag) {
      if ($(this).hasClass('is-open')) {
        $('+ ul', this).slideUp(animeSpd);
        $(this).removeClass('is-open');
      } else {
        $('+ ul', this).slideDown(animeSpd);
        $(this).addClass('is-open');
      }
    }
  });

  // sp フッターナビ開閉
  $jsSitePagefooterNavisPrimaryTitle.on('click', function () {
    if (spflag) {
      if ($(this).hasClass('is-open')) {
        $('+ ul', this).slideUp(animeSpd);
        $(this).removeClass('is-open');
      } else {
        $('+ ul', this).slideDown(animeSpd);
        $(this).addClass('is-open');
      }
    }
  });

  // PC 検索窓
  $('.js-pageheader-searchicon').on('click', function () {
    if (!spflag) {
      $jsSitePageheaderMainnaviSearchbox.addClass('is-open');
      $('.js-pageheader-inputbox').focus();
      $('.js-site-searchheadcloser').addClass('is-open');
      $('.js-site-searchheadcloser').removeClass('is-close');
    }
  });
  $('.js-site-searchheader-closeicon').on('click', function () {
    if (!spflag) {
      $jsSitePageheaderMainnaviSearchbox.removeClass('is-open');
      $('.js-site-searchheadcloser').addClass('is-close');
    }
  });

  // モデルページ内現在地表示
  if (location.pathname != "/") {
    var productpath01 = location.pathname.split("/")[2],
        productpath02 = location.pathname.split("/")[3],
        $productnav = $('.js-product-navi-list a');
    $productnav.each(function () {
      var $href = $(this).attr("href");
      if ($href == productpath01 || $href == productpath02) {
        $(this).addClass('is-active');
      }
    });
  } else $('.js-product-navi-list a:eq(0)').addClass('is-active');

  // スクロール時のアクション
  $(window).on('scroll', function () {
    // フッターコンタクト表示・非表示
    if ($(this).scrollTop() > footercontactsShowHeight) {
      $jsSiteFootercontacts.addClass('is-shown');
    } else {
      $jsSiteFootercontacts.removeClass('is-shown');
    }
  });

  function windowHeightSet () {
    // ウインドウ高さ処理
    var vh = window.innerHeight * 0.01;
    // カスタム変数--vhの値をドキュメントのルートに設定
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }
  windowHeightSet();

});
